import React, { FC } from 'react';

interface Props {
  children: React.ReactNode;
  to: string;
  onClick(): void;
  appDefinitionId: string;
  forceFullPageNavigation: boolean;
  ariaLabel?: string;
  testId: string;
}

export const Link: FC<Props> = ({
  children,
  to,
  onClick,
  ariaLabel,
  testId,
}) => (
  <a
    target="_top"
    href={to}
    onClick={() => onClick()}
    aria-label={ariaLabel}
    data-testid={testId}
  >
    {children}
  </a>
);

export default Link;
