import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';
import { st, classes } from './EmptyState.st.css';

export const EmptyState: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Text className={classes.title} data-hook="empty-state-title">
        {t('app.widget.empty-state.title')}
      </Text>
      <Text className={classes.subtitle}>
        {t('app.widget.empty-state.subtitle')}
      </Text>
    </div>
  );
};
