import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { st, classes } from './MarkAllAsReadButton.st.css';

interface Props {
  unseenCount: number;
  onClick(): void;
}

export const MarkAllAsReadButton: FC<Props> = ({ unseenCount, onClick }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <TextButton
      type="button"
      priority={TextButtonPriority.secondary}
      onClick={onClick}
      className={st(classes.button, { isMobile })}
    >
      {t('app.widget.mark-all-as-read', {
        unseenCount: unseenCount > 99 ? '99+' : unseenCount,
      })}
    </TextButton>
  );
};
