import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';
import moment from 'moment';
import { classes } from './NotificationItem.st.css';

type Time = string | number | Date;
interface Props {
  time: Time;
  dataHook: string;
}

const DEFAULT_DATE_FORMAT = 'MMM D';
const LAST_WEEK = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
function isWeekBefore(time: Time) {
  return new Date(time).getTime() < LAST_WEEK;
}

const SPECIAL_LOCALES: Record<string, string> = {
  tl: 'tl-ph',
  zh: 'zh-tw',
  no: 'nb',
};
const SPECIAL_FORMATS: Record<string, string> = {
  ko: 'MMM Do',
  ja: 'MMMDo',
};

export const TimeAgo: FC<Props> = ({ time, dataHook }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const locale = SPECIAL_LOCALES[language] || language;
  const format = SPECIAL_FORMATS[language] || DEFAULT_DATE_FORMAT;
  const text = isWeekBefore(time)
    ? moment(time).locale(locale).format(format)
    : moment(time).locale(locale).fromNow();

  return (
    <Text className={classes.content} data-hook={dataHook}>
      {text}
    </Text>
  );
};
