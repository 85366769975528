import React, { FC, useCallback } from 'react';
import { Avatar, Text } from 'wix-ui-tpa/cssVars';
import { notificationClickOnNotification } from '@wix/bi-logger-dash/v2';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import type { MembersViewerFeedNotification } from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/types';
import { st, classes } from './NotificationItem.st.css';
import { TimeAgo } from './TimeAgo';
import Link from './Link';

interface Props {
  notification: MembersViewerFeedNotification;
  markAsRead(id: string): void;
  forceReadStatus: boolean;
}

export const NotificationItem: FC<Props> = (props) => {
  const bi = useBi();
  const { isMobile } = useEnvironment();
  const { notification } = props;
  const url = notification.deeplink?.webUrlData?.webUrl!;
  const isRead =
    props.forceReadStatus ||
    notification.status === 'READ' ||
    notification.status === 'RESOLVED';
  const onClick = useCallback(() => {
    bi.report(
      notificationClickOnNotification({
        channel: 'web',
        click_type: 'click',
        deep_link: url,
        is_high_priority: false,
        feed_name: 'members_area',
        notificationid: notification.id,
      }),
    );
    if (!isRead) {
      props.markAsRead(notification.id!);
    }
  }, [bi, isRead, notification.id, props, url]);

  return (
    <Link
      testId={`notification-item-${notification.id}`}
      to={url}
      onClick={onClick}
      appDefinitionId={notification.appMetadata?.appId!}
      forceFullPageNavigation={true}
    >
      <div className={st(classes.row, { isMobile })}>
        <div style={{ marginRight: '18px' }}>
          <Avatar
            src={notification.icon?.personAvatarData?.data}
            data-hook={`notification-avatar-${notification.id}`}
          />
        </div>
        <div className={classes.contentWrapper}>
          <div>
            <Text
              className={classes.content}
              data-hook={`notification-content-${notification.id}`}
            >
              {notification.message}
            </Text>
          </div>
          <div className={classes.timeAgo}>
            <TimeAgo
              time={notification.sentDate!}
              dataHook={`notification-time-${notification.id}`}
            />
          </div>
        </div>
        <div className={classes.dotWrapper}>
          {!isRead ? <span className={classes.dot} /> : null}
        </div>
      </div>
    </Link>
  );
};
