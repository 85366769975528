import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  useBi,
  useEnvironment,
  useTranslation,
  type WidgetProps,
} from '@wix/yoshi-flow-editor';
import { notificationShowNotification } from '@wix/bi-logger-dash/v2';
import type { QueryViewerFeedNotificationsResponse } from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/types';
import { Text, Divider } from 'wix-ui-tpa/cssVars';
import { NotificationItem } from './components/notification-item';
import { MarkAllAsReadButton } from './components/mark-all-as-read-button';
import { st, classes } from './Widget.st.css';
import { EmptyState } from './components/empty-state';
import { useIntersection } from './hooks/useIntersection';
import { ThreeDotsLoader } from 'wix-ui-tpa';

export type ControllerProps = {
  notificationsRes?: QueryViewerFeedNotificationsResponse;
  unseenCount: number;
  markAsRead(id: string): void;
  markAllAsRead(): void;
  getNextPage(cursor: string): Promise<QueryViewerFeedNotificationsResponse>;
};

export const Widget: FC<WidgetProps<ControllerProps>> = ({
  notificationsRes,
  unseenCount,
  getNextPage,
  markAllAsRead,
  markAsRead,
}) => {
  const { t } = useTranslation();
  const bi = useBi();
  const { isMobile, isSSR } = useEnvironment();
  const [notifications, setNotifications] = useState(
    notificationsRes?.viewerFeedNotifications,
  );
  const [cursor, setCursor] = useState(
    notificationsRes?.pagingMetadata?.cursors?.next,
  );
  const isEmpty = !notifications?.length;
  const [hasUnseen, setHasUnseen] = useState(unseenCount > 0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const lastNotificationRef = useRef(null);
  const isLastNotificationVisible = useIntersection(lastNotificationRef, '0px');

  useEffect(() => {
    if (
      notificationsRes?.viewerFeedNotifications &&
      notificationsRes?.pagingMetadata?.cursors?.next !== cursor
    ) {
      setNotifications([
        ...(notifications || []),
        ...notificationsRes?.viewerFeedNotifications!,
      ]);
      setCursor(notificationsRes?.pagingMetadata?.cursors?.next);
      setPageNumber(pageNumber + 1);
    }
    setIsLoading(false);
  }, [notificationsRes]);

  useEffect(() => {
    if (!isSSR && notificationsRes?.viewerFeedNotifications?.length) {
      bi.report(
        notificationShowNotification({
          channel: 'web',
          feed_name: 'members_area',
          page_number: pageNumber,
          notification_ids: notificationsRes.viewerFeedNotifications
            .map((n) => n.id)
            .join(','),
        }),
      );
    }
  }, [pageNumber]);

  const onLastNotificationVisible = useCallback(async () => {
    if (cursor && !isLoading) {
      setIsLoading(true);
      getNextPage(cursor);
    }
  }, [cursor, isLoading, getNextPage]);

  useEffect(() => {
    if (isLastNotificationVisible) {
      onLastNotificationVisible();
    }
  }, [isLastNotificationVisible]);

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook="OoiNotifications-wrapper"
    >
      {(!isMobile || hasUnseen) && (
        <div className={classes.header}>
          {!isMobile && (
            <Text data-hook="app-title" className={classes.title}>
              {t('app.widget.title')}
            </Text>
          )}
          {hasUnseen && (
            <MarkAllAsReadButton
              unseenCount={unseenCount}
              onClick={() => {
                markAllAsRead();
                setHasUnseen(false);
              }}
            />
          )}
        </div>
      )}
      {!isEmpty && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.notificationsList}>
            {notifications?.map((n, i, arr) => (
              <div
                key={n.id}
                ref={i === arr.length - 1 ? lastNotificationRef : undefined}
              >
                <NotificationItem
                  notification={n}
                  markAsRead={markAsRead}
                  forceReadStatus={!hasUnseen}
                />
                <Divider className={classes.divider} />
              </div>
            ))}
            {isLoading && (
              <div className={classes.loaderWrapper}>
                <ThreeDotsLoader />
              </div>
            )}
          </div>
        </>
      )}
      {isEmpty && <EmptyState />}
    </div>
  );
};
